import styled, { css } from 'styled-components'

export const StyledPartnersPageWrapper = styled.main`
  padding: 20px;
  width: 100%;
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-page']};
  `}
`

export const StyledPartnersSection = styled.section`
  max-width: 1608px;
`

export const StyledPartnersGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-auto-rows: max-content;
  width: 100%;

  @media (min-width: 2560px) {
    grid-template-columns: repeat(auto-fit, 385px);
  }

  @media (min-width: 1920px) and (max-width: 2559px) {
    grid-template-columns: repeat(auto-fit, 385px);
  }

  @media (min-width: 1441px) and (max-width: 1919px) {
    grid-template-columns: repeat(auto-fit, 360px);
  }

  @media (min-width: 1201px) and (max-width: 1440px) {
    grid-template-columns: repeat(auto-fit, 360px);
  }
  @media only screen and (min-width: 635px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 634px) {
    grid-template-columns: 1fr;
  }
`
