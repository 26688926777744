import styled from 'styled-components'

export const StyledSupportPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  ${({ theme }) => `
    background: ${theme.tokens.colors.surface['surface-base']};
  `}
`
