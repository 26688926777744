const consentMap = {
  ru: '/documents/consent_ru.pdf',
  en: '/documents/consent_en.pdf',
  kz: '/documents/consent_kz.pdf',
  uz: '/documents/consent_uz.pdf',
  tr: '/documents/consent_tr.pdf',
  cn: '/documents/consent_cn.pdf'
}

const documentsList = {
  consent: consentMap
}

export const getDocumentLink = async (
  cdnUrl: string,
  documentName: string,
  locale: string
) =>
  new Promise<string>((resolve) => {
    if (documentName === 'rules') {
      try {
        const link = `${cdnUrl}/rules_${locale}.pdf`
        fetch(link)
          .then((response) => {
            if (response.status < 300) {
              resolve(link)
            } else {
              resolve(`${cdnUrl}/rules_ru.pdf`)
            }
          })
          .catch(() => resolve(`${cdnUrl}/rules_ru.pdf`))
      } catch (e) {
        console.log(e)
        resolve(`${cdnUrl}/rules_ru.pdf`)
      }
    }
    if (documentName === 'consent') {
      if (documentsList.consent[locale]) {
        resolve(documentsList.consent[locale])
      }
      resolve(documentsList.consent.en)
    }
  })
