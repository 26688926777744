import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { getCurrentLng } from 'astra-core/utils'
import { FC, useCallback, useEffect, useState } from 'react'

import { BasketButtonOpenBasketForSmallWindowIFrame } from 'features/basket'
import { useAppSelector } from 'shared/lib/@reduxjs'
import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import { IFRAME_DATA } from './Statistics.constants'
import {
  StyledLoadingIndicatorWrapper,
  StyledStatisticsWrapper
} from './Statistics.styled'

export const Statistics: FC = () => {
  const [isLoading, setIsLoading] = useState(true)

  const currentLang = getCurrentLng()
  const { BETRADAR_STATISTICS_URL } = useAppSelector(selectConfig)

  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  useEffect(() => {
    setIsLoading(true)
  }, [currentLang])

  const handleStatisticsIframeLoaded = useCallback(() => {
    setIsLoading(false)
  }, [])

  return (
    <StyledStatisticsWrapper>
      {isLoading && (
        <StyledLoadingIndicatorWrapper>
          <LoaderSpinner />
        </StyledLoadingIndicatorWrapper>
      )}

      {(isTabletOrMobile || isSmall) && (
        <BasketButtonOpenBasketForSmallWindowIFrame isSmall />
      )}

      <iframe
        frameBorder="0"
        id={IFRAME_DATA.id}
        src={`${BETRADAR_STATISTICS_URL}/${currentLang}`}
        style={IFRAME_DATA.styles}
        title="iframeStatistics"
        onLoad={handleStatisticsIframeLoaded}
      />
    </StyledStatisticsWrapper>
  )
}
