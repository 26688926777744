import styled from 'styled-components'

export const StyledPartnerCard = styled.div`
  background: ${({ theme }) =>
    theme.tokens.colors.surface['surface-card-default']};
  border-radius: ${({ theme }) =>
    theme.tokens.alias['border-radius']['border-radius-l-12']}px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 280px;
  padding: 16px;
`

export const StyledPartnerLogo = styled.div`
  height: 142px;
  margin-bottom: 12px;
  width: 100%;

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
`

export const StyledPartnerName = styled.h3`
  color: ${({ theme }) => theme.tokens.colors.text['text-primary']};
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin: 0 0 8px 0;
  text-align: left;
`

export const StyledPartnerDescription = styled.p`
  color: ${({ theme }) => theme.tokens.colors.text['text-primary-2']};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  text-align: left;
`
