import React, { FC, memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isFeatureFlagEnabled } from 'astra-core/containers/ConfigProvider'

import { filterRoutes, isActiveCurrentPath } from 'shared/lib/routes'

import { INavMenuItemProps } from './profile-nav-menu.type'
import {
  StyledNavMenu,
  StyledNavMenuItem,
  StyledNavMenuItemCounter,
  StyledNavMenuItemLabel
} from './profile-nav-menu.styled'
import {
  PROFILE_MENU_AUTHORIZED_ROUTES_LIST,
  PROFILE_MENU_NON_AUTHORIZED_ROUTES_LIST
} from './routes'

export const ProfileNavMenu: FC<{ isAuthorized?: boolean }> = memo(
  ({ isAuthorized }) => {
    const [t] = useTranslation()

    const menuItems = useMemo(() => {
      const routeList = isAuthorized
        ? PROFILE_MENU_AUTHORIZED_ROUTES_LIST
        : PROFILE_MENU_NON_AUTHORIZED_ROUTES_LIST

      return filterRoutes({
        routeList,
        t,
        forwardProps: ({ counter, featureFlag }) => ({ counter, featureFlag })
      }) as unknown as INavMenuItemProps[]
    }, [isAuthorized, t])

    return (
      <StyledNavMenu>
        {menuItems.map((item) => (
          <ProfileNavMenuItem
            counter={item.counter}
            key={item.path}
            {...item}
          />
        ))}
      </StyledNavMenu>
    )
  }
)

const ProfileNavMenuItem: FC<INavMenuItemProps> = memo(
  ({
    counter: Counter,
    featureFlag,
    path,
    translatedName,
    icon: Icon = null
  }) => {
    const { pathname } = useLocation()

    const isActiveItem = useMemo(
      () => isActiveCurrentPath(pathname, path) || pathname === path,
      [pathname, path]
    )

    const isEnabled = !featureFlag || isFeatureFlagEnabled(featureFlag)

    if (!isEnabled) return null

    return (
      <StyledNavMenuItem $isActiveItem={isActiveItem} key={path} to={path}>
        {Icon && <Icon size={24} />}

        <StyledNavMenuItemLabel>{translatedName}</StyledNavMenuItemLabel>

        {Counter ? (
          <Counter>
            {(count) =>
              count > 0 ? (
                <StyledNavMenuItemCounter $isActiveItem={isActiveItem}>
                  {count}
                </StyledNavMenuItemCounter>
              ) : null
            }
          </Counter>
        ) : null}
      </StyledNavMenuItem>
    )
  }
)
