import React from 'react'
import { useParams } from 'react-router-dom'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { FAQDetailsParams } from 'entities/faq'
import { useFAQTranslation } from 'entities/faq/lib/utils'
import { UnderConstructionPage } from 'pages/page-under-construction/under-construction-page'

import { StyledFAQDetailsPage } from './FAQDetailsPage.styled'

export const FAQDetailsPage = () => {
  const { faqId } = useParams<FAQDetailsParams>()
  const translation = useFAQTranslation(faqId)
  const FAQ_ENABLED = useFeatureFlag('FAQ_ENABLED' as EFeatureFlags)

  if (!FAQ_ENABLED) return <UnderConstructionPage />

  return (
    <StyledFAQDetailsPage>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: translation?.html
        }}
      />
    </StyledFAQDetailsPage>
  )
}
