import { FC } from 'react'

import { PartnerCard } from 'entities/partners'

import { PARTNERS } from '../partners.constants'

import {
  StyledPartnersGrid,
  StyledPartnersPageWrapper,
  StyledPartnersSection
} from './PartnersPage.styled'

export const PartnersPage: FC = () => {
  return (
    <StyledPartnersPageWrapper>
      <StyledPartnersSection>
        <StyledPartnersGrid>
          {PARTNERS.map((partner) => (
            <PartnerCard key={partner.translationKey} partner={partner} />
          ))}
        </StyledPartnersGrid>
      </StyledPartnersSection>
    </StyledPartnersPageWrapper>
  )
}
