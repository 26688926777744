import styled from 'styled-components'

import { DataListWithReduxPagination } from 'shared/ui/DataList'

export const StyledHistory = styled.section`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
`

export const StyledColumn = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`

export const StyledListWrapper = styled(DataListWithReduxPagination)`
  justify-self: center;
  max-width: 1640px;
  overflow: unset;
  width: 100%;
`
