import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledWarningContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    display: flex;
    padding: ${theme.tokens.alias.space['space-m-16']}px;
    padding-top: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledWarningContentContainer = styled.div`
  ${typography['Body / 14 Medium']}
  display: flex;
  flex-direction: column;
  width: 720px;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
    gap: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledWarningImgsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledWarningDescription = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-disabled']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    color: ${theme.tokens.colors.text['text-primary']};
    height: min-content;
    outline: 1px solid ${theme.tokens.colors.divider['divider-primary']};
    outline-offset: -1px;
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledWarningRisksList = styled.div`
  & ul li {
    list-style-position: inside;
    list-style-type: disc;

    & > span {
      margin-left: -6px;
    }
  }
`

export const StyledWarningImage = styled.img`
  height: 222px;
  width: 356px;
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    outline: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    outline-offset: -1px;
  `}
`
