import { useGroupedEventsByGroupId } from 'astra-core'
import { FC, Fragment } from 'react'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useTheme } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'

import { EventsTableProps } from 'widgets/events-table/ui/events-table/events-table.types'
import {
  EventTableHeader,
  EventTableRow,
  useHotProbsMarketHeaderConfig
} from 'features/events-table'
import { EventsTableWrapper } from 'widgets/events-table/ui/events-table/events-table.styled'
import {
  BlockWrapper,
  HeaderCell,
  StyledBlocksCell,
  StyledBlocksWrapper
} from 'features/events-table/ui/table-header/event-table-header.styled'
import { OutcomesComponentType, OutcomesGrid } from 'widgets/outcomes-grid'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { groupEventGpoupsBySport } from '../../lib/groupEventGroupsBySport'

import {
  EventsGroupWrapper,
  EventsTableGroupsWrapper,
  StyledHeaderWrapper,
  StyledTR
} from './events-favorite-table.styled'
import { TableCap } from './ui'

export const EventsFavoriteTable: FC<EventsTableProps> = ({
  events,
  headerProps,
  rowProps
}) => {
  const { t } = useTranslation()

  const eventsGroups = useGroupedEventsByGroupId(events)

  const { IS_IFRAME } = useAppSelector(selectConfig)

  const theme = useTheme()

  const groupedEventGroups = groupEventGpoupsBySport(
    eventsGroups.slice().sort((a, b) => a.sportId - b.sportId)
  )

  const { headerConfig } = useHotProbsMarketHeaderConfig({
    sportId: 100
  })

  return (
    <EventsTableGroupsWrapper>
      {groupedEventGroups.map((item) => (
        <EventsGroupWrapper isIframe={IS_IFRAME} key={item[0].id}>
          <StyledHeaderWrapper isIframe={IS_IFRAME}>
            {!IS_IFRAME && theme.typeLayoutTheme === LayoutTheme.Dark && (
              <TableCap />
            )}
            <StyledTR>
              <StyledBlocksCell colSpan={2}>
                <StyledBlocksWrapper>
                  {headerConfig?.map((block) => (
                    <BlockWrapper isMerged={block.isMerged} key={block.id}>
                      {block.config.map((item) => (
                        <Fragment key={item.localeKey || item.label}>
                          <HeaderCell customWidth={item.customWidth}>
                            {item.localeKey ? t(item.localeKey) : item.label}
                          </HeaderCell>
                        </Fragment>
                      ))}
                    </BlockWrapper>
                  ))}
                </StyledBlocksWrapper>
              </StyledBlocksCell>
            </StyledTR>
          </StyledHeaderWrapper>
          {item.map((group) => (
            <EventsTableWrapper key={group.id}>
              <thead>
                <EventTableHeader
                  tournamentName={group.name}
                  tournamentSportId={group.sportId}
                  hideOutcomeCodes
                  {...headerProps}
                />
              </thead>
              <tbody>
                {group.events.map((event) => (
                  <EventTableRow
                    Outcomes={
                      <OutcomesGrid
                        eventId={event.id}
                        type={OutcomesComponentType.EVENTS_LIST}
                      />
                    }
                    event={event}
                    key={event.id}
                    {...rowProps}
                  />
                ))}
              </tbody>
            </EventsTableWrapper>
          ))}
        </EventsGroupWrapper>
      ))}
    </EventsTableGroupsWrapper>
  )
}
