import styled from 'styled-components'

import { StyledGameCardImg } from 'features/game-card/game-card.styled'

/* Width relative to the GameCard width */
export const StyledCrashBoxingImg = styled(StyledGameCardImg)`
  @container game-card (width >= 98px) {
    right: -61px;
  }

  @container game-card (width >= 117px) {
    right: -41px;
  }

  @container game-card (width >= 135px) {
    right: -33px;
  }

  @container game-card (width >= 147px) {
    right: -25px;
  }

  @container game-card (width >= 170px) {
    right: 3px;
  }

  @container game-card (width >= 266px) {
    right: 6px;
  }
`
