import { IPartner } from 'entities/partners'

export const PARTNERS: IPartner[] = [
  {
    translationKey: 'kff',
    images: {
      light: {
        image1x: '/images/partners/light/kff.png',
        image2x: '/images/partners/light/kff@2x.png',
        image3x: '/images/partners/light/kff@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/kff.png',
        image2x: '/images/partners/light/kff@2x.png',
        image3x: '/images/partners/light/kff@3x.png'
      }
    }
  },
  {
    translationKey: 'astana',
    images: {
      light: {
        image1x: '/images/partners/light/astana.png',
        image2x: '/images/partners/light/astana@2x.png',
        image3x: '/images/partners/light/astana@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/astana.png',
        image2x: '/images/partners/light/astana@2x.png',
        image3x: '/images/partners/light/astana@3x.png'
      }
    }
  },
  {
    translationKey: 'tobol',
    images: {
      light: {
        image1x: '/images/partners/light/tobol.png',
        image2x: '/images/partners/light/tobol@2x.png',
        image3x: '/images/partners/light/tobol@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/tobol.png',
        image2x: '/images/partners/light/tobol@2x.png',
        image3x: '/images/partners/light/tobol@3x.png'
      }
    }
  },
  {
    translationKey: 'shahtyor',
    images: {
      light: {
        image1x: '/images/partners/light/shahtyor.png',
        image2x: '/images/partners/light/shahtyor@2x.png',
        image3x: '/images/partners/light/shahtyor@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/shahtyor.png',
        image2x: '/images/partners/light/shahtyor@2x.png',
        image3x: '/images/partners/light/shahtyor@3x.png'
      }
    }
  },
  {
    translationKey: 'atyrau',
    images: {
      light: {
        image1x: '/images/partners/light/atyrau.png',
        image2x: '/images/partners/light/atyrau@2x.png',
        image3x: '/images/partners/light/atyrau@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/atyrau.png',
        image2x: '/images/partners/light/atyrau@2x.png',
        image3x: '/images/partners/light/atyrau@3x.png'
      }
    }
  },
  {
    translationKey: 'ordabasy',
    images: {
      light: {
        image1x: '/images/partners/light/ordabasy.png',
        image2x: '/images/partners/light/ordabasy@2x.png',
        image3x: '/images/partners/light/ordabasy@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/ordabasy.png',
        image2x: '/images/partners/light/ordabasy@2x.png',
        image3x: '/images/partners/light/ordabasy@3x.png'
      }
    }
  },
  {
    translationKey: 'aktobe',
    images: {
      light: {
        image1x: '/images/partners/light/aktobe.png',
        image2x: '/images/partners/light/aktobe@2x.png',
        image3x: '/images/partners/light/aktobe@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/aktobe.png',
        image2x: '/images/partners/light/aktobe@2x.png',
        image3x: '/images/partners/light/aktobe@3x.png'
      }
    }
  },
  {
    translationKey: 'borba_rk',
    images: {
      light: {
        image1x: '/images/partners/light/greko_rimskaya_borba.png',
        image2x: '/images/partners/light/greko_rimskaya_borba@2x.png',
        image3x: '/images/partners/light/greko_rimskaya_borba@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/greko_rimskaya_borba.png',
        image2x: '/images/partners/light/greko_rimskaya_borba@2x.png',
        image3x: '/images/partners/light/greko_rimskaya_borba@3x.png'
      }
    }
  },
  {
    translationKey: 'hardcore',
    images: {
      light: {
        image1x: '/images/partners/light/hardcore.png',
        image2x: '/images/partners/light/hardcore@2x.png',
        image3x: '/images/partners/light/hardcore@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/hardcore.png',
        image2x: '/images/partners/light/hardcore@2x.png',
        image3x: '/images/partners/light/hardcore@3x.png'
      }
    }
  },
  {
    translationKey: 'nastol_tennis',
    images: {
      light: {
        image1x: '/images/partners/light/nastol_tennis.png',
        image2x: '/images/partners/light/nastol_tennis@2x.png',
        image3x: '/images/partners/light/nastol_tennis@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/nastol_tennis.png',
        image2x: '/images/partners/light/nastol_tennis@2x.png',
        image3x: '/images/partners/light/nastol_tennis@3x.png'
      }
    }
  },
  {
    translationKey: 'topdog',
    images: {
      light: {
        image1x: '/images/partners/light/topdog.png',
        image2x: '/images/partners/light/topdog@2x.png',
        image3x: '/images/partners/light/topdog@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/topdog.png',
        image2x: '/images/partners/light/topdog@2x.png',
        image3x: '/images/partners/light/topdog@3x.png'
      }
    }
  },
  {
    translationKey: 'boevie',
    images: {
      light: {
        image1x: '/images/partners/light/boevie.png',
        image2x: '/images/partners/light/boevie@2x.png',
        image3x: '/images/partners/light/boevie@3x.png'
      },
      dark: {
        image1x: '/images/partners/dark/boevie.png',
        image2x: '/images/partners/dark/boevie@2x.png',
        image3x: '/images/partners/dark/boevie@3x.png'
      }
    }
  },
  {
    translationKey: 'alash',
    images: {
      light: {
        image1x: '/images/partners/light/alash.png',
        image2x: '/images/partners/light/alash@2x.png',
        image3x: '/images/partners/light/alash@3x.png'
      },
      dark: {
        image1x: '/images/partners/light/alash.png',
        image2x: '/images/partners/light/alash@2x.png',
        image3x: '/images/partners/light/alash@3x.png'
      }
    }
  },
  {
    translationKey: 'baige',
    images: {
      light: {
        image1x: '/images/partners/light/baige.png',
        image2x: '/images/partners/light/baige@2x.png',
        image3x: '/images/partners/light/baige@3x.png'
      },
      dark: {
        image1x: '/images/partners/dark/baige.png',
        image2x: '/images/partners/dark/baige@2x.png',
        image3x: '/images/partners/dark/baige@3x.png'
      }
    }
  },
  {
    translationKey: 'nomad',
    images: {
      light: {
        image1x: '/images/partners/light/nomad.png',
        image2x: '/images/partners/light/nomad@2x.png',
        image3x: '/images/partners/light/nomad@3x.png'
      },
      dark: {
        image1x: '/images/partners/dark/nomad.png',
        image2x: '/images/partners/dark/nomad@2x.png',
        image3x: '/images/partners/dark/nomad@3x.png'
      }
    }
  }
]
