import { LayoutTheme } from 'betweb-openapi-axios'
import styled, { css } from 'styled-components'

import { AppLink } from 'shared/lib/app-link'
import { typography } from 'shared/lib/theme/typography'

export const StyledGameCardLink = styled(AppLink)`
  ${({ theme }) =>
    theme.typeLayoutTheme === LayoutTheme.Light
      ? css`
          background: linear-gradient(
            101.73deg,
            rgba(108, 121, 147, 0.2) 28.69%,
            #a7afbe 99.9%
          );

          &:hover {
            background: linear-gradient(
              101.73deg,
              rgba(108, 121, 147, 0.15) 28.69%,
              #c3c9d4 99.9%
            );
          }

          &:active {
            background: linear-gradient(
              101.73deg,
              rgba(108, 121, 147, 0.32) 28.69%,
              #666d7c 99.9%
            );
          }
        `
      : css`
          background: linear-gradient(
            101.73deg,
            rgba(158, 204, 250, 0.25) 28.69%,
            #4c5567 99.9%
          );

          &:hover {
            background: linear-gradient(
              101.73deg,
              rgba(158, 204, 250, 0.15) 28.69%,
              #5c6577 99.9%
            );
          }

          &:active {
            background: linear-gradient(
              101.73deg,
              rgba(158, 204, 250, 0.32) 28.69%,
              #3c4557 99.9%
            );
          }
        `}

  container: game-card / size;
  display: block;
  height: 80px;
  overflow: hidden;
  position: relative;

  transition: background 0.3s ease-in-out;

  img {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
  }

  &:hover img {
    transform: scale(1.101);
  }

  &:active img {
    transform: scale(1.101);
  }
`

export const StyledGameCardText = styled.span`
  ${({ theme }) => css`
    ${typography['Body / 16 ExtraBold']}
    color: ${theme.tokens.colors.text['text-primary']};
    font-family: 'Manrope', sans-serif;
    left: 8px;
    line-height: 17px;
    position: absolute;
    top: 10px;
    white-space: pre-line;
    z-index: 1;
  `}
`

export const StyledGameCardBgImg = styled.img`
  height: 80px;
  object-fit: contain;
  object-position: left top;
  position: absolute;
  width: 272px;
`

export const StyledGameCardImg = styled.img`
  height: 80px;
  position: absolute;
  top: 0px;
  z-index: 1;
`
