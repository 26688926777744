import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { IPartnerCardProps } from '../../model/types'

import {
  StyledPartnerCard,
  StyledPartnerDescription,
  StyledPartnerLogo,
  StyledPartnerName
} from './partner-card.styled'

export const PartnerCard: FC<IPartnerCardProps> = memo(({ partner }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const images = theme.isDark ? partner.images.dark : partner.images.light

  const name = partner.translationKey
    ? t(`partners.${partner.translationKey}.name`)
    : ''

  const description = partner.translationKey
    ? t(`partners.${partner.translationKey}.description`)
    : ''

  return (
    <StyledPartnerCard>
      <StyledPartnerLogo>
        <img
          srcSet={`${images.image2x} 2x${
            images.image3x ? `, ${images.image3x} 3x` : ''
          }`}
          alt={name}
          loading="lazy"
          src={images.image1x}
        />
      </StyledPartnerLogo>
      <StyledPartnerName>{name}</StyledPartnerName>
      <StyledPartnerDescription>{description}</StyledPartnerDescription>
    </StyledPartnerCard>
  )
})
