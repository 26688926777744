import React, { memo } from 'react'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { UnderConstructionPage } from 'pages/page-under-construction/under-construction-page'
import { SupportContent, SupportHeader } from 'widgets/support'

import { StyledSupportPage } from './SupportPage.styled'

export const SupportPage = memo(() => {
  const SUPPORT_ENABLED = useFeatureFlag('SUPPORT_ENABLED' as EFeatureFlags)
  const FAQ_ENABLED = useFeatureFlag('FAQ_ENABLED' as EFeatureFlags)

  if (!SUPPORT_ENABLED) return <UnderConstructionPage />

  return (
    <StyledSupportPage>
      <SupportHeader />
      {FAQ_ENABLED ? <SupportContent /> : null}
    </StyledSupportPage>
  )
})
