import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { MAIN_FOOTER_HEIGHT } from 'shared/lib/layout'

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - ${MAIN_FOOTER_HEIGHT}px);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const EmptyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const EmptyContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
  text-align: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const EmptyText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const EmptyTitle = styled.span`
  ${typography['Body / 16 Bold']};
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const EmptyDescription = styled.span`
  ${typography['Body / 14 SemiBold']};
  text-align: center;
  white-space: break-spaces;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
  `}
`

export const StyledBetsDate = styled.div`
  ${typography['Body / 12 Medium']}

  align-items: center;
  display: flex;

  position: sticky;
  top: 212px;
  z-index: 1;

  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-page']};
    color: ${theme.tokens.colors.text['text-secondary-2']};
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px 0px
      ${theme.tokens.alias.space['space-xxxxs-2']}px;

    & span {
      ${typography['Body / 12 Bold']}
      color: ${theme.tokens.colors.text['text-primary']};
    }
  `}
`
