export enum EWarningImageIds {
  G_ADDICTION = 'gambling-addiction',
  G_MENTAL_HEALTH_PROBLEM = 'gambling-mental-health-problem',
  G_DESTROY_THE_FAMILY = 'gambling-destroy-the-family',
  G_WORK_AND_STUDY = 'gambling-work-and-study',
  B_ADDICTION = 'betting-addiction',
  B_MENTAL_HEALTH_PROBLEM = 'betting-mental-health-problem',
  B_DESTROY_THE_FAMILY = 'betting-destroy-the-family',
  B_WORK_AND_STUDY = 'betting-work-and-study'
}

export type TWarningImageListItem = {
  warningImgId: EWarningImageIds
  warningImgSrc: string
}

export type TWarningImagesIds = {
  id: EWarningImageIds
  fileName: string
}

export type TWarningRisksListItem = {
  id: string
  text: string
}
