import { useTranslation } from 'react-i18next'

import { ESportGamesProductIds } from 'pages/page-sport-games/page.types'
import { ERoutes } from 'shared/types/routes'
import { Skeleton } from 'shared/ui/skeleton'

import { GameCardLoader } from '../../game-card.loader'
import { StyledGameCardLink, StyledGameCardText } from '../../game-card.styled'
import image1x from '../lib/nard@1x.png'
import image2x from '../lib/nard@2x.png'
import image3x from '../lib/nard@3x.png'

import { StyledNardImg } from './nard-game-card.styled'

export const NardGameCard = () => {
  const { t } = useTranslation()

  return (
    <StyledGameCardLink
      to={{
        pathname: ERoutes.SportGamesLobby,
        state: { productId: ESportGamesProductIds.NARD }
      }}
    >
      <Skeleton isLoading={false} styledFallback={<GameCardLoader />}>
        <StyledNardImg
          alt="nard image"
          srcSet={`${image1x} 1x, ${image2x} 2x, ${image3x} 3x`}
        />
        <StyledGameCardText>{t('nard')}</StyledGameCardText>
      </Skeleton>
    </StyledGameCardLink>
  )
}
