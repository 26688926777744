import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBonusClub: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m12.51 2.102 6.128 2.537c.327.136.586.395.722.722l2.538 6.128c.136.327.136.693 0 1.02l-2.538 6.128a1.33 1.33 0 0 1-.722.722l-6.128 2.539a1.33 1.33 0 0 1-1.02 0l-6.128-2.54a1.33 1.33 0 0 1-.722-.72l-2.538-6.129a1.33 1.33 0 0 1 0-1.02l2.537-6.128c.136-.327.395-.586.722-.722l6.13-2.537a1.33 1.33 0 0 1 1.019 0m6.587 10.217-1.815 1.578.002-.001h1.66c.106 0 .177.107.137.205l-.923 2.227a1.33 1.33 0 0 1-.721.721l-4.925 2.04a1.33 1.33 0 0 1-1.02 0l-4.925-2.04a1.33 1.33 0 0 1-.721-.721L4.923 14.1a.148.148 0 0 1 .137-.205h1.66l-1.815-1.578a1.33 1.33 0 0 1-.357-1.515l1.023-2.47a.147.147 0 0 1 .234-.055l1.124.977V7.687c0-.54.324-1.026.823-1.232l2.459-1.019a.148.148 0 0 1 .205.137v1.002c0 .415-.193.805-.521 1.058L7.328 9.602v4.294l4.674 4.062 4.673-4.062V9.602l-2.567-1.97a1.33 1.33 0 0 1-.521-1.057V5.573c0-.106.108-.177.205-.137l2.46 1.02c.497.206.822.692.822 1.231v1.57l1.124-.978a.148.148 0 0 1 .234.055l1.022 2.47c.219.529.076 1.14-.357 1.515"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
