import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { Scrollable } from 'shared/ui/scrollable'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { StyledDocumentFrame } from './DocumentFrame.styled'
import { getDocumentLink } from './constants'

export const DocumentFrame: FC<{ documentName: string }> = ({
  documentName
}) => {
  const config = useAppSelector(selectConfig)
  const [link, setLink] = useState('')
  const { i18n } = useTranslation()

  useEffect(() => {
    getDocumentLink(
      `${config.CDN_URL}/${config.RULES_CDN_FOLDER}`,
      documentName,
      i18n.language
    ).then(setLink)
  }, [config.CDN_URL, config.RULES_CDN_FOLDER, documentName, i18n.language])

  return (
    <Scrollable>
      <StyledDocumentFrame>
        {link && (
          <embed
            height="100%"
            src={`${link}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
            type="application/pdf"
            width="1000"
          />
        )}
      </StyledDocumentFrame>
    </Scrollable>
  )
}
