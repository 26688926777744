import {
  EWarningImageIds,
  TWarningImagesIds,
  TWarningRisksListItem
} from './warning.types'

export const WARNING_IMAGES_IDS: TWarningImagesIds[] = [
  { id: EWarningImageIds.G_ADDICTION, fileName: 'gambling-addiction.png' },
  {
    id: EWarningImageIds.G_MENTAL_HEALTH_PROBLEM,
    fileName: 'gambling-mental-health-problem.png'
  },
  {
    id: EWarningImageIds.G_DESTROY_THE_FAMILY,
    fileName: 'gambling-destroy-the-family.png'
  },
  {
    id: EWarningImageIds.G_WORK_AND_STUDY,
    fileName: 'gambling-work-and-study.png'
  },
  { id: EWarningImageIds.B_ADDICTION, fileName: 'betting-addiction.png' },
  {
    id: EWarningImageIds.B_MENTAL_HEALTH_PROBLEM,
    fileName: 'betting-mental-health-problem.png'
  },
  {
    id: EWarningImageIds.B_DESTROY_THE_FAMILY,
    fileName: 'betting-destroy-the-family.png'
  },
  {
    id: EWarningImageIds.B_WORK_AND_STUDY,
    fileName: 'betting-work-and-study.png'
  }
]

export const WARNING_RISKS_LIST: TWarningRisksListItem[] = [
  { id: 'addiction', text: 'warning.page_risks.addiction' },
  { id: 'mental_health', text: 'warning.page_risks.mental_health' },
  {
    id: 'social_consequences',
    text: 'warning.page_risks.social_consequences'
  },
  { id: 'work_and_study', text: 'warning.page_risks.work_and_study' }
]
