import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { getCurrentLng } from 'astra-core/utils'
import { FC, useCallback, useEffect, useState } from 'react'

import { BasketButtonOpenBasketForSmallWindowIFrame } from 'features/basket'
import { useAppSelector } from 'shared/lib/@reduxjs'
import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import { IFRAME_DATA } from './LiveResults.constants'
import {
  StyledLiveResultsWrapper,
  StyledLoadingIndicatorLiveResultsWrapper
} from './LiveResults.styled'

export const LiveResults: FC = () => {
  const [isLoading, setIsLoading] = useState(true)

  const currentLang = getCurrentLng()
  const { BETRADAR_LIVE_RESULTS_URL } = useAppSelector(selectConfig)

  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  useEffect(() => {
    setIsLoading(true)
  }, [currentLang])

  const handleStatisticsIframeLoaded = useCallback(() => {
    setIsLoading(false)
  }, [])

  return (
    <StyledLiveResultsWrapper>
      {isLoading && (
        <StyledLoadingIndicatorLiveResultsWrapper>
          <LoaderSpinner />
        </StyledLoadingIndicatorLiveResultsWrapper>
      )}

      {(isTabletOrMobile || isSmall) && (
        <BasketButtonOpenBasketForSmallWindowIFrame isSmall />
      )}

      <iframe
        frameBorder="0"
        id={IFRAME_DATA.id}
        src={`${BETRADAR_LIVE_RESULTS_URL}/${currentLang}`}
        style={IFRAME_DATA.styles}
        title="iframeLiveResultsId"
        onLoad={handleStatisticsIframeLoaded}
      />
    </StyledLiveResultsWrapper>
  )
}
