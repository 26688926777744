import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import {
  StyledWarningContainer,
  StyledWarningContentContainer,
  StyledWarningDescription,
  StyledWarningImage,
  StyledWarningImgsWrapper,
  StyledWarningRisksList
} from './warning.styled'
import { WARNING_IMAGES_IDS, WARNING_RISKS_LIST } from './constants'
import { TWarningImageListItem } from './warning.types'

const supportedLangs = ['kz', 'ru']

export const WarningContent = () => {
  const [t] = useTranslation()
  const { i18n } = useTranslation()

  const currentLang = useMemo(() => {
    return supportedLangs.includes(i18n.language) ? i18n.language : 'en'
  }, [i18n.language])

  const WARNING_IMAGES_LIST: TWarningImageListItem[] = WARNING_IMAGES_IDS.map(
    (item) => ({
      warningImgId: item.id,
      warningImgSrc: `/images/bet-warning/${currentLang}/${
        item.fileName
      }?v=${Date.now()}`
    })
  )

  return (
    <StyledWarningContainer>
      <StyledWarningContentContainer>
        <StyledWarningDescription>
          {t('warning.page_main_description')}
        </StyledWarningDescription>
        <StyledWarningImgsWrapper>
          {WARNING_IMAGES_LIST.map((img) => (
            <StyledWarningImage
              key={img.warningImgId}
              src={img.warningImgSrc}
            />
          ))}
        </StyledWarningImgsWrapper>
        <StyledWarningRisksList>
          {t('warning.page_risks.title')}
          <ul style={{ paddingLeft: '8px' }}>
            {WARNING_RISKS_LIST.map((item) => (
              <li key={item.id}>
                <span>{t(item.text)}</span>
              </li>
            ))}
          </ul>
        </StyledWarningRisksList>
        <span>{t('warning.page_responsible_play')}</span>
        <span>{t('warning.page_farewell')}</span>
      </StyledWarningContentContainer>
    </StyledWarningContainer>
  )
}
