import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ProfileMenu } from 'widgets/profile-menu'
import { FooterOfPage } from 'widgets/footer-of-page'
import { AppLink } from 'shared/lib/app-link'
import { IconButton } from 'shared/ui/buttons/IconButton'
import { IconArrowLeft } from 'shared/ui/Icon/General/IconArrowLeft'
import {
  ColumnLayout,
  ColumnLayoutScroll,
  ColumnTypes
} from 'shared/lib/layout'

import {
  BackLinkWrapper,
  StyledContentProfile,
  StyledContentTitleProfile
} from './profile.styled'
import { ProfileProps } from './profile.types'

export const Profile = memo<ProfileProps>(
  ({
    pageTitle,
    children,
    isHeaderFilled,
    isHiddenFooter,
    buttonBack,
    customHeaderStyle
  }) => {
    const [t] = useTranslation()

    return (
      <ColumnLayout leftGridArea="profile-menu" type={ColumnTypes.TWO_COLUMN}>
        <ProfileMenu />

        <ColumnLayoutScroll>
          <StyledContentProfile pageTitle={pageTitle?.text}>
            {pageTitle?.text && (
              <StyledContentTitleProfile
                customHeaderStyle={customHeaderStyle}
                isHeaderFilled={isHeaderFilled}
                {...pageTitle}
              >
                <BackLinkWrapper>
                  {buttonBack?.to && (
                    <AppLink to={buttonBack?.to}>
                      <IconButton
                        icon={IconArrowLeft}
                        iconColorToken="icon-secondary"
                        view="fill-l"
                      />
                    </AppLink>
                  )}
                  {t(pageTitle.text)}
                </BackLinkWrapper>
              </StyledContentTitleProfile>
            )}

            {children}

            {isHiddenFooter || <FooterOfPage />}
          </StyledContentProfile>
        </ColumnLayoutScroll>
      </ColumnLayout>
    )
  }
)
