import React from 'react'

import { LayoutProfile } from 'layouts/profile'

import { WarningContent } from './ui'

export const PageWarning = () => {
  return (
    <LayoutProfile
      customHeaderStyle={{ whiteSpace: 'pre-wrap' }}
      pageTitle={{ text: 'warning.header_title' }}
      isHeaderFilled
    >
      <WarningContent />
    </LayoutProfile>
  )
}

export default PageWarning
