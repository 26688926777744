import { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconPartners: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.427 4.979a.445.445 0 0 1-.51.085 148 148 0 0 0-3.86-1.83 2.62 2.62 0 0 0-2.266.05L9.942 4.71c-.834.418-1.19 1.47-.793 2.351.396.88 1.394 1.256 2.229.838L13.47 6.85a.67.67 0 0 1 .794.15l5.434 5.85c.212.228.33.534.33.854v1.017h1.045c.512 0 .926-.438.926-.977V5.61c0-.674-.518-1.22-1.157-1.22h-.36c-.292 0-.573.116-.787.325z"
          fill="#6C7993"
        />
        <path
          d="M3.157 4.083C2.518 4.083 2 4.629 2 5.303v9.367h1.078l5.68 5.442c1.325 1.27 3.374 1.165 4.577-.232l4.124-4.79a1.52 1.52 0 0 0-.094-2.07l-3.656-3.502c-.28-.27-.712-.243-1.018-.005a3.53 3.53 0 0 1-2.175.753c-2.027 0-3.67-1.733-3.67-3.87 0-.868.27-1.668.727-2.313z"
          fill="#6C7993"
        />
      </svg>
    </Icon>
  )
}
