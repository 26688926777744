import { Event, LogoEntityType, Sport } from 'betweb-openapi-axios'
import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'

import { selectEntityTags, sortByEntityTags } from 'entities/event'
import { getLogos } from 'entities/logos/actions'
import { EventCard } from 'features/event-card'
import { SportTabs } from 'features/sport-tabs'
import { SportTabsLoader } from 'features/sport-tabs/sport-tabs.loader'
import { TopEventsLoader } from 'pages/page-main/Main/components/top-events/top-events.loader'
import {
  selectTopEvents,
  selectTopEventsCurrentSportId,
  selectTopEventsLoaded,
  selectTopEventsSports
} from 'pages/page-main/MainPageContainer/selectors'
import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'
import { usePrevious } from 'shared/lib/use-previous/usePrevious'
import { Carousel } from 'shared/ui/Carousel'
import { Skeleton } from 'shared/ui/skeleton'

import {
  SLIDES_GAP,
  SLIDES_HEIGHT,
  SLIDES_MAX_WIDTH,
  SLIDES_MIN_WIDTH
} from './top-events.constants'
import {
  StyledEventsCarouselWrapper,
  StyledMainPageEventsHeader,
  StyledMainPageEventsHeaderWrapper,
  StyledMainPageEventsWrapper,
  StyledSportListWrapper
} from './top-events.styled'

const TopEventsHeader = memo(() => {
  const { t } = useTranslation()

  return (
    <StyledMainPageEventsHeaderWrapper>
      <StyledMainPageEventsHeader>{t('top events')}</StyledMainPageEventsHeader>
    </StyledMainPageEventsHeaderWrapper>
  )
})

const SportListSkeleton = memo(() => {
  const dispatch = useAppDispatch()

  const isLoaded = useAppSelector(selectTopEventsLoaded)
  const sports = useAppSelector(selectTopEventsSports, isEqual)
  const activeSport = useAppSelector(selectTopEventsCurrentSportId)
  const onSportChange = useCallback(
    (sportId: Sport['id']) => {
      dispatch(mainPageContainerActions.selectTopEventsSport({ sportId }))
    },
    [dispatch]
  )

  return (
    <StyledSportListWrapper>
      <Skeleton isLoading={!isLoaded} styledFallback={<SportTabsLoader />}>
        <SportTabs
          activeSport={activeSport}
          items={sports}
          onChange={onSportChange}
        />
      </Skeleton>
    </StyledSportListWrapper>
  )
})

export const TopEvents = () => {
  const events = useAppSelector(selectTopEvents)

  const isLoaded = useAppSelector(selectTopEventsLoaded)

  if (!events.length && isLoaded) {
    return null
  }

  return (
    <StyledMainPageEventsWrapper>
      <TopEventsHeader />

      <SportListSkeleton />

      <EventsCarousel events={events} />
    </StyledMainPageEventsWrapper>
  )
}

type EventsCarouselProps = {
  events: Event[]
}

const EventsCarousel: FC<EventsCarouselProps> = ({ events }) => {
  const dispatch = useAppDispatch()
  const prevEvents = usePrevious(events, true)
  const isLoaded = useAppSelector(selectTopEventsLoaded)
  const entityTags = useAppSelector(selectEntityTags)

  const sortedEventsByTags = useMemo(() => {
    const liveEvents = events.filter((e) => e.live)
    const lineEvents = events.filter((e) => !e.live)

    const sortedLive = sortByEntityTags(liveEvents, entityTags)
    const sortedLine = sortByEntityTags(lineEvents, entityTags)

    return [...sortedLive, ...sortedLine]
  }, [events, entityTags])

  useEffect(() => {
    if (prevEvents?.length !== events.length) {
      const competitorIds = events.reduce((acc, cur) => {
        acc.push(...cur.competitors.map((c) => c.id))
        return acc
      }, [] as number[])

      dispatch(
        getLogos({
          entityType: LogoEntityType.Competitor,
          ids: competitorIds
        })
      )
    }
  }, [dispatch, events, prevEvents?.length])

  return (
    <StyledEventsCarouselWrapper>
      <Skeleton isLoading={!isLoaded} styledFallback={<TopEventsLoader />}>
        <Carousel
          childHeight={SLIDES_HEIGHT}
          childMaxWidth={SLIDES_MAX_WIDTH}
          childMinWidth={SLIDES_MIN_WIDTH}
          leftOffset={0}
          rightOffset={0}
          slidesGap={SLIDES_GAP}
        >
          {sortedEventsByTags.map((event) => (
            <EventCard event={event} key={event.id} />
          ))}
        </Carousel>
      </Skeleton>
    </StyledEventsCarouselWrapper>
  )
}
