import { FC, memo } from 'react'

import { LayoutPartners } from 'layouts/partners'

import { PartnersPage } from './Partners/PartnersPage'

export const PagePartners: FC = memo(() => {
  return (
    <LayoutPartners pageTitle={{ text: 'partners.title' }}>
      <PartnersPage />
    </LayoutPartners>
  )
})

export default PagePartners
